import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as he from 'he';

import { ActivatedRoute } from '@angular/router';

import { CampaignFormat, ResponseCampaignFormats } from 'src/app/shared/interfaces/response-campaign-formats.interface';
import { Campaign, ResponseCampaign } from 'src/app/shared/interfaces/response-campaign.interface';
import { ResponseCampaignData } from 'src/app/shared/interfaces/response-campaign-data.interface';

import { environment } from 'src/environments/environment';

import { CampaignService } from 'src/app/admon/services/campaign.service';
import { DataService } from 'src/app/admon/services/data.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { CampaignDeta, ResponseCampaignDeta } from 'src/app/shared/interfaces/response-campaign-deta.interface';

@Component({
  selector: 'app-preview-detail',
  templateUrl: './preview-detail.component.html',
  styleUrls: ['./preview-detail.component.css']
})
export class PreviewDetailComponent {
  html: string = "";
  safeHtml: SafeResourceUrl | undefined;
  id: string = '';
  formatId: string = '';
  campaignId: string = '';
  campaign: Campaign = null!;
  loading: boolean = true;
  pathStorage: string = environment.baseApiVariations + '/api/file';
  campaignData: ResponseCampaignData = {
    result: []
  };
  campaignFormats: CampaignFormat[] = [];

  constructor(private sanitizer: DomSanitizer,
              private campaignService: CampaignService,
              private dataService: DataService,
              private route: ActivatedRoute,
              private utilidadesService: UtilidadesService){}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = this.utilidadesService.desencriptar(params["id"]);
      this.formatId = this.utilidadesService.desencriptar(params["formatId"]);
      this.campaignId = this.utilidadesService.desencriptar(params["campaignId"]);

      this.campaignService.getCampaignByID(Number(this.campaignId)).subscribe({
        next: (response: ResponseCampaign) => {
          this.campaign = response.result[0];

          if(this.campaign.status === "I"){
            this.utilidadesService.mostrarModal('Error', `Campaign was inactivated`);
            return;
          }

          this.campaignService.getCampaignDataById(this.id).subscribe({
            next: (response: ResponseCampaignData) => {
              this.campaignData = response;

              if (this.campaignData.result.length === 0){
                this.utilidadesService.mostrarModal('Info', `Not found data by filters selected.`);
                this.loading = false;
                return;
              }

              const campaignData = response;

              this.campaignService.getCampaignFormatsByIDs(this.formatId).subscribe({
                next: (response: ResponseCampaignFormats) => {
                  this.campaignFormats = response.result;

                  this.campaignFormats.forEach(format => {
                    this.campaignService.getCampaignDeta(format.id).subscribe({
                      next: (responseDeta: ResponseCampaignDeta) => {
                        let fields = responseDeta.result;
                        console.log("fields by format", fields);
                        this.createHtml(format, fields, campaignData);
                        this.loading = false;
                      },
                      error: () => {
                        this.utilidadesService.mostrarModal('Error', `Error load data format.`);
                        this.loading = false;
                      }});
                  });
                },
                error: () => {
                  this.utilidadesService.mostrarModal('Error', `Error load formats to show.`);
                }
              });
            },
            error: () => {
              this.utilidadesService.mostrarModal('Error', `Error load data.`);
            }
          });
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    });
  }

  createHtml(format: CampaignFormat, fields: CampaignDeta[], campaignData: ResponseCampaignData): void {
    let path = this.pathStorage + "?path=" + this.campaign.path + "&path_format=" + format.path + "&name=";
    this.dataService.getDataOrigin(path + "index.html").subscribe({
      next: (dataHtml: any) => {

        campaignData.result.forEach(data => {
          //Reemplazos de rutas
          let htmlContent = this.cleanHtml(dataHtml, path);

          //Reemplaza los datos
          fields.forEach(filter => {
            const field = data[`field_${filter.order}`];
            console.log(filter.field_html, field);

            const dynamicContentStart = htmlContent.indexOf('var devDynamicContent = {};');
            const dynamicContentEnd = htmlContent.indexOf('Enabler.setDevDynamicContent(devDynamicContent);');
            const dynamicContent = htmlContent.substring(dynamicContentStart, dynamicContentEnd).trim();

            let dataColumns = dynamicContent.split(';');

            dataColumns.slice(1).forEach((element: string) => {
              const fieldSearch = this.dataService.getPath(element, []);

              if(fieldSearch.trim().toLowerCase() === filter.field_html.trim().toLowerCase()){
                console.log('Encontrado', field, filter.field_html);
                const value = element.split(" = ");
                let valueDecode = field?.replace(/&Acirc;/g, "");
                valueDecode = valueDecode?.replace(/&acirc;/g, "");
                valueDecode = valueDecode?.replace(/\u0094/g, "");
                valueDecode = he.decode(valueDecode!).replace(/"/gi, "'");
                const newValue = ` = "${valueDecode}"`;

                console.log("valor buscado", element);
                console.log("reemplazo", valueDecode);

                htmlContent = htmlContent.replace(element, `${value[0]}${newValue}`);
                return;
              }
            });
          });

          htmlContent = this.addScriptToHead(htmlContent);

          //Crea el HTML
          let sanitizedHtml: SafeResourceUrl | undefined;
          const blob = new Blob([htmlContent], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          sanitizedHtml = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.safeHtml = sanitizedHtml;
          this.html = htmlContent;
        });

    },
    error: (msg: any) => {
      console.log("Error convert html", msg);
      this.utilidadesService.mostrarModal('Error', `Error load html to convert.`);
    }});
    }

    cleanHtml(value: string, path: string): string {
      let valueFinal = value;
      valueFinal = valueFinal.replace(/gwd-image source="/g, 'gwd-image source="' + path);
      valueFinal = valueFinal.replace(/mask-image:url\("/g, 'mask-image:url("' + path);
      valueFinal = valueFinal.replace(/src:url\("/g, 'src:url("' + path);
      valueFinal = valueFinal.replace(/src: url\('/g, 'src: url(\'' + path);
      valueFinal = valueFinal.replace(/,url\("/g, ',url("' + path);
      valueFinal = valueFinal.replace(/gwd-image( id="[^"]*")? source="([^"]*)"/g, (match: string, idAttr: string, sourceAttr: string) => {
        return `gwd-image${idAttr || ''} source="${path}${sourceAttr}"`;
      });
      valueFinal = this.decodeUnicode(valueFinal);

      return valueFinal;

    }

    decodeUnicode(input: string): string {
      return input.replace(/\\u[\dA-F]{4}/gi, (match) => {
        return String.fromCharCode(parseInt(match.replace("\\u", ""), 16));
      });
    }

    addScriptToHead(htmlContent: string): string {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      const script = doc.createElement('script');
      script.setAttribute('data-source', 'https://s0.2mdn.net/ads/studio/Enabler.js');
      script.setAttribute('data-exports-type', 'gwd-google-ad');
      script.src = 'https://s0.2mdn.net/ads/studio/Enabler.js';
      doc.head.appendChild(script);

      return doc.documentElement.outerHTML;
    }

    refreshIframe(): void {
      this.ngOnInit();
    }
}
