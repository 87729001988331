<!--div class="showpreview">
  <div class="showpreview__creatives">
    <div style="padding-bottom: 80px;">
        <div class="col-md-12 text-uppercase mb-4">
            <h2 class="card p-1 ps-3">{{campaign != null ? campaign.name : ''}}</h2>
        </div>
        <div style="display: flex; flex-wrap: wrap; align-items: flex-start;">
          <div class="card-iframe">
              <div class="card-iframe__info">
                  <span class="card-iframe__info-size">
                    {{(campaignFormats[0].name.toString() || '')}}
                  </span>
                  <button class="card-iframe__info-reload"
                      (click)="refreshIframe()">
                      <img alt="Refresh Icon" src="/assets/img/reload-icon.png">
                  </button>
              </div>
              <span class="title-creative">{{(campaignData.result[0]['field_2']?.toString() || '')}}</span>
              <iframe [src]="safeHtml"
                [width]="campaignFormats[0].width"
                [height]="campaignFormats[0].height"
                *ngIf="safeHtml">
                  <p>Your browser does not support iframes.</p>
              </iframe>
          </div>
        </div>
    </div>
  </div>
</div-->
<iframe [src]="safeHtml"
  [width]="campaignFormats[0].width"
  [height]="campaignFormats[0].height"
  *ngIf="safeHtml">
    <p>Your browser does not support iframes.</p>
</iframe>
