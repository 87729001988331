import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule }from '@swimlane/ngx-charts';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CampaignsComponent } from './admon/pages/campaigns/campaigns.component';
import { CreateCampaignComponent } from './admon/pages/campaigns/create-campaign/create-campaign.component';
import { CreateFormatComponent } from './admon/pages/campaigns/create-format/create-format.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './user/pages/home/home.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { PreviewComponent } from './user/pages/preview/preview.component';
import { TermsConditionsComponent } from './user/pages/terms-conditions/terms-conditions.component';

import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { UserComponent } from './admon/pages/user/user.component';
import { RegionComponent } from './admon/pages/region/region.component';
import { AdvertiserComponent } from './admon/pages/advertiser/advertiser.component';
import { CreateAdvertiserComponent } from './admon/pages/advertiser/create-advertiser/create-advertiser.component';
import { CreateRegionComponent } from './admon/pages/region/create-region/create-region.component';
import { CreateUserComponent } from './admon/pages/user/create-user/create-user.component';
import { CampaignComponent } from './user/pages/campaign/campaign.component';
import { PreviewDetailComponent } from './user/pages/preview-detail/preview-detail.component';

@NgModule({
  declarations: [
    AdvertiserComponent,
    AppComponent,
    CampaignsComponent,
    CampaignComponent,
    CreateAdvertiserComponent,
    CreateCampaignComponent,
    CreateFormatComponent,
    CreateRegionComponent,
    CreateUserComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    PreviewComponent,
    RegionComponent,
    TermsConditionsComponent,
    UserComponent,
    PreviewDetailComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule,
    NgxChartsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
