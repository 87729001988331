import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { DialogShowComponent } from '../components/dialog-show/dialog-show.component';

import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {

  private _secrePass: string = environment.key;

  constructor(private dialog: MatDialog,
              private router: Router) { }

  encriptar(param: string) {
    const ciphertext = AES.encrypt(param, this._secrePass);
    return encodeURIComponent(ciphertext.toString());
  }

  convertirFechaFormato(fecha: Date, format:string){
    return formatDate(fecha, format, 'en')
  }

  desencriptar(param: string) {
    const decodedStr = decodeURIComponent(param);
    return AES.decrypt(decodedStr, this._secrePass).toString(enc.Utf8);
  }

  calcularOffsetPaginacion(pageIndex: number, pageSize: number): number {
    let offset: number = 0;
    if(pageIndex === 0){
      offset = 0;
    } else {
      offset = (pageIndex * pageSize);
    }
    return offset;
  }

  campoEsValido( formulario: UntypedFormGroup, campo: string ) {
    return formulario.controls[campo].errors
            && formulario.controls[campo].touched;
  }

  mostrarModal(titulo: string, texto: string, textoBoton: string = 'Aceptar', width: string = '25%'){
    return this.dialog.open(DialogShowComponent, {
      data: {titulo: titulo, texto: texto, textoBoton: textoBoton },
      width: width,
    });
  }

  startSound (id: any, loop: any) {
    let soundHandle: any = document.getElementById(id);
    if(loop)
      soundHandle.setAttribute('loop', loop);
    soundHandle.play();
  }

  pausedSound(id: string){
    let soundHandle: any = document.getElementById(id);
    soundHandle.pause();
  }

  mostrarOcultar(id: string, mostrar: boolean) {
    let element: HTMLElement = document.getElementById(id)!;

    if (mostrar) {
      element.style.display = 'block';
    } else {
      element.style.display = 'none';
    }
  }

  escribirHtml(id: string, texto: string) {
    let element: HTMLElement = document.getElementById(id)!;
    element.innerHTML = texto;
  }

  goHome(): void {
    this.router.navigate(['/', 'home']);
  }

  irLogin(): void {
    this.router.navigate(['/', 'login']);
  }
}
